import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import supabase from './supabase';
import InputWithButton from './components/InputWithButton';
import { useRef } from 'react';


const Start = () => {
  const listNameRef = useRef(null);
  return (
    <Container maxWidth="sm">
      <Box sx={{
        display:'flex',
        alignItems:"center",
        flexDirection:'column',
        height:"100vh"
      }}>
        <Box sx={{
          fontFamily: `'Fira Mono', sans-serif`,
          fontSize: "36px",
          width: "100%",
          marginTop:"8px"
        }}>
          <div>
            ⚡ quicklist
          </div>
          <div style={{fontSize: "18px"}}>
            <small>Realtime shared lists</small>
          </div>
        </Box>
        <Box sx={{
          fontFamily: `'Fira Mono', sans-serif`,
          fontSize: "24px",
          width: "100%"
        }}>
          <p>
            Shared lists in realtime that are good for doing groceries, chores 
            and whatever you need that other people do together with you, 
            keeping track of what is left in the list in a easy way.
          </p>
        </Box>
        <Box sx={{
            backgroundColor: "#5762D5", 
            paddingY: "36px", 
            width:"100%",
            display: "flex",
            justifyContent: "center"
          }}>
          <InputWithButton ref={listNameRef} onSubmit={
            () => asyncCreate(listNameRef.current.value)
          }/>
        </Box>
      </Box>
    </Container>
  );
}

const asyncCreate = async (listName) => {
  console.log("async create....");
  const { data, error } = await supabase.functions.invoke('create-list', {
    body: JSON.stringify({ name: listName || 'Unknown' }),
  });
  const {data: rows, error:opError} = data;

  if(error || opError){
    console.error("Error in async create", data, error);
    //TODO error toast
  }else{
    console.log("No error, data:", data, rows);
    const listId = rows[0].id;
    window.location = "/" + listId;
  }
}

export default Start;
