import Container from '@mui/material/Container';
import { useParams } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import supabase from './supabase';
import { useState, useEffect, useRef } from "react";
import { useSwipeable, LEFT, RIGHT } from 'react-swipeable';


const List = () => {
  const {listId} = useParams();
  const [items, setItems] = useState([]);
  const subscriptionRef = useRef(null);
  const inputRef = useRef(null);
  const itemsRef = useRef(items);
  const listElref = useRef(null);
  const allSetItems = (items, scroll = true) => {
    itemsRef.current = items;
    setItems(items);
    if(scroll){
      listElref.current.scrollIntoView(false);
    }
  }

  useEffect(() => {
    (async () => {
      const listItems = await fetchList(listId);
      console.log("received items", listItems);
      allSetItems(listItems);

      //setup subcription  only once
      if(!subscriptionRef.current) {
        console.log("subscribinnnng...")
        subscriptionRef.current = supabase
          .channel('db-changes')
          .on(
            'postgres_changes',
            { 
              event: '*', schema: 'public', table: 'list_items', 
              filter: `list_id=eq.${listId}`
            }, 
            (payload) => allSetItems(
              mergeItemList(itemsRef.current, payload),
              payload.eventType !== 'DELETE'
            )
          )
          .subscribe();
      }
    })();
  },[listId]);

  
  const handleKeyDown = (event) => {
    const itemName = event.target.value;
    if (event.key === 'Enter' && itemName && itemName.trim().length > 0) {
      event.target.value = "";
      putInList(listId, itemName);
    }
  };

  const deleteItem = (item, direction) => {
    console.log("swiped", item, direction)
    //TODO animate?
    removeFromList(item.id);
  }

  return <Container>
    <Box sx={{paddingTop:"16px", maxHeight:"calc(100vh - 60px)", overflowY:"scroll"}}>
      <Stack spacing={2} ref={listElref} sx={{paddingBottom: "16px"}}>
        {items.map(
          it => <ListItem item={it} onSwipe={deleteItem} key={it.id}/>
        )}
      </Stack>
    </Box>
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: "12px"}} elevation={3}>
      <TextField ref={inputRef} fullWidth placeholder="Add item to list" id="fullWidth" variant="standard"
        onKeyDown={handleKeyDown} />
    </Paper>
  </Container>;
}

const ListItem = ({item, onSwipe}) => {
  const handlers = useSwipeable({
    onSwipedRight: (_eventData) => onSwipe(item, RIGHT),
    onSwipedLeft: (_eventData) => onSwipe(item, LEFT),
    trackMouse: true,
    trackTouch: true,
  });

  return <Box sx={{maxWidth:"50%"}}>
    <div {...handlers}>
      <Chip label={item.name} color="info" />
    </div>
  </Box>
}


const fetchList = async (listId) => {
  console.log("async create....");
  const { data, error } = await supabase.functions.invoke('read-list', {
    body: JSON.stringify({ listId }),
  });
  const {data: rows, error:opError} = data;

  if(error || opError){
    console.error("Error in async create", data, error);
    //TODO error toast
    return [];
  }

  return rows;
}

const putInList = async (listId, name) => {
  console.log("async putIn....");
  const { data, error } = await supabase.functions.invoke('put-in-list', {
    body: JSON.stringify({ listId, name }),
  });
  const {data: rows, error:opError} = data;

  console.log("done putIn....");
  if(error || opError){
    console.error("Error in async create", data, error);
    //TODO error toast
    return false;
  }

  return true;
}

const removeFromList = async (itemId) => {
  console.log("async removeFromList....");
  const { data, error } = await supabase.functions.invoke('remove-from-list', {
    body: JSON.stringify({ itemId }),
  });
  const {error:opError} = data;

  console.log("done removeFromList....");
  if(error || opError){
    console.error("Error in async remove From list", data, error);
    //TODO error toast
    return false;
  }

  return true;
}

const mergeItemList = (currentItems, event) => {
  const type = event.eventType;
  
  if(type == "INSERT") {
    return [...currentItems, event.new];
  }

  if(type == "DELETE") {
    console.log("EVT", event, currentItems, event.old)
    return currentItems.filter(it => it.id !== event.old.id);
  }

}

export default List;