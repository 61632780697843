import { createClient } from '@supabase/supabase-js'

const options = {
  schema: 'public',
  autoRefreshToken: true,
  persistSession: true,
  detectSessionInUrl: true,
}
const supabase = createClient(
  'https://cprpnbzsfjqxfymeijcr.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNwcnBuYnpzZmpxeGZ5bWVpamNyIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjE2NDI3MDUsImV4cCI6MTk3NzIxODcwNX0.iZuOj02n0gvNRO-J_cA00x3LvoIfJKWbFzOsJMvFeyU',
  options
)

export default supabase;