import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import App from "./App";
import List from "./List";
import Start from "./Start";
  
const Router = () => {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<Start />} />
                <Route path=":listId" element={<List />} />
            </Route>
        </Routes>
    </BrowserRouter>
}

export default Router;